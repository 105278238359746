




















































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios, { AxiosResponse, AxiosError } from "axios";
import {
  MAX_EMAIL_ADDRESS_LENGTH,
  MIN_EMAIL_ADDRESS_LENGTH,
  MAX_PHONE_NUMBER_LENGTH,
  MIN_PHONE_NUMBER_LENGTH,
  EMAIL_ADDRESS_REGEX,
  PHONE_NUMBER_REGEX,
  MIN_CLAIM_REQUEST_DESCRIPTION_LENGTH,
  MAX_CLAIM_REQUEST_DESCRIPTION_LENGTH
} from "../../services/config/constants";

import dateformat from "../../util/date-format";
import { User } from "../../datatypes/User";
import { Category } from "../../datatypes/Category";
import {
  Claim,
  ClaimConfirmation,
  ClaimRequest,
  ClaimRequestType,
  ClaimStatus
} from "../../datatypes/Claim";
import isPlainObject from "lodash/isPlainObject";

enum Status {
  IDLE = "idle",
  FOUND = "found",
  SUBMITTING = "submitting",
  SUCCESS = "success"
}

import { getCSRFToken, extractErrorMessage } from "../../services/actions";
import { nonEmptyString } from "../../util/string";
import { zonedTimeToUtc } from "date-fns-tz";
import endOfDay from "date-fns/endOfDay";
import { scrollBehavior } from "../../util/dom";

import get from "lodash/get";
import flatten from "lodash/flatten";
import has from "lodash/has";
import { rules } from "../../util/validation";

const DEFAULT_CLAIM_REQUEST = {
  Type: ClaimRequestType.SURVIVOR_ESCALATION_REQUEST,
  Description: ""
};

@Component
export default class ClaimCreationForm extends Vue {
  timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  claim: Claim | null = null;
  firstname: string = "";
  emailAddress: string = "";
  phoneNumber: string = "";
  hasClaimRequest: boolean = false;
  claimRequest: Pick<
    ClaimRequest,
    "Type" | "Description"
  > = DEFAULT_CLAIM_REQUEST;
  preferredContactMethod: "emailAddress" | "phoneNumber" = "emailAddress";
  formerrors: Record<string, string[]> = {};
  category: number | null = null;
  lob: string = "";
  status: Status = Status.IDLE;
  loading: boolean = false;
  confirmation: string = "";
  errors: string[] = [];
  csrfToken: string = "";

  mounted() {
    this.getCSRFToken();
  }

  @Watch("hasClaimRequest", { immediate: true })
  onClaimRequestToggle(val: boolean) {
    if (!val) this.$set(this.claimRequest, "Description", "");
  }

  get user(): User | null {
    return this.$store.getters["session/user"];
  }

  getCSRFToken() {
    getCSRFToken()
      .then(token => (this.csrfToken = token))
      .catch(err => console.error("Failure to get csrf token", err));
  }

  get constants() {
    return {
      EMAIL_ADDRESS_REGEX,
      MAX_EMAIL_ADDRESS_LENGTH,
      MIN_EMAIL_ADDRESS_LENGTH,
      MIN_PHONE_NUMBER_LENGTH,
      MAX_PHONE_NUMBER_LENGTH,
      PHONE_NUMBER_REGEX,
      MIN_CLAIM_REQUEST_DESCRIPTION_LENGTH,
      MAX_CLAIM_REQUEST_DESCRIPTION_LENGTH
    };
  }

  cancel() {
    this.emailAddress = "";
    this.phoneNumber = "";
    this.preferredContactMethod = "emailAddress";
    this.category = null;
    this.lob = "";
    this.status = Status.IDLE;
    this.loading = false;
    this.confirmation = "";
    this.errors = [];
    this.claim = null;
    this.formerrors = {};
    this.hasClaimRequest = false;
    this.$set(this.claimRequest, "Description", "");
    this.$set(this.$data, "claimRequest", DEFAULT_CLAIM_REQUEST);
  }

  reset() {
    this.emailAddress = "";
    this.phoneNumber = "";
    this.preferredContactMethod = "emailAddress";
    this.category = null;
    this.lob = "";
    this.status = Status.IDLE;
    this.hasClaimRequest = false;
    this.$set(this.claimRequest, "Description", "");
    this.$set(this.$data, "claimRequest", DEFAULT_CLAIM_REQUEST);
    this.loading = false;
    this.confirmation = "";
    this.claim = null;
    this.errors = [];
    const $form = document.getElementById(
      "claim-submission"
    ) as HTMLFormElement;

    if ($form) {
      $form.reset();
      $form.noValidate = true;
      $form.noValidate = false;
    }

    const $finderForm = document.getElementById(
      "claim-finder"
    ) as HTMLFormElement;
    if ($finderForm) {
      $finderForm.reset();
    }
  }

  private formattimestamp(timestamp: number) {
    return dateformat("P", timestamp);
  }

  get disableformfields() {
    return this.isAlreadySubmitted || this.isExpired;
  }

  get isExpired() {
    const now = endOfDay(zonedTimeToUtc(new Date(), this.timezone)).getTime();
    return (
      this.claim &&
      isPlainObject(this.claim) &&
      now > Number(this.claim.ExpirationTimestamp)
    );
  }

  get isAlreadySubmitted() {
    return (
      this.claim &&
      isPlainObject(this.claim) &&
      [
        Number(ClaimStatus.SUBMITTED),
        Number(ClaimStatus.ACKNOWLEDGED)
      ].includes(Number(this.claim.Status))
    );
  }

  validation() {
    this.formerrors = ["emailAddress", "phoneNumber", "claimRequest"].reduce(
      (errorbag: Record<string, string[]>, field: string) => {
        switch (field) {
          case "emailAddress": {
            const isvalid = rules.string.email(get(this.$data, field));
            return isvalid
              ? errorbag
              : {
                  ...errorbag,
                  emailAddress: ["Valid email address is required"]
                };
          }

          case "phoneNumber": {
            const value = get(this.$data, field);
            const isvalid = rules.string.phone(value.trim());
            return isvalid
              ? errorbag
              : this.preferredContactMethod === "phoneNumber" || value.length
              ? { ...errorbag, phoneNumber: ["Valid phone number is required"] }
              : errorbag;
          }

          case "claimRequest": {
            const value = get(this.$data, field);
            const isvalid =
              !this.hasClaimRequest ||
              (isPlainObject(this.claimRequest) &&
                this.claimRequest.Type ===
                  ClaimRequestType.SURVIVOR_ESCALATION_REQUEST &&
                nonEmptyString(this.claimRequest.Description));

            return isvalid
              ? errorbag
              : {
                  ...errorbag,
                  claimRequest: [
                    `Valid escalation request description required. Minimum length: ${this.constants.MIN_CLAIM_REQUEST_DESCRIPTION_LENGTH}.`
                  ]
                };
          }

          default: {
            return errorbag;
          }
        }
      },
      {} as Record<string, string[]>
    );
  }

  search() {
    if (this.confirmation.trim() && this.confirmation.trim().length >= 7) {
      this.errors = [];
      this.loading = true;
      axios(
        `/api/v1/claims/search/${encodeURIComponent(this.confirmation.trim())}`
      )
        .then(response => {
          this.getCSRFToken();
          this.loading = false;
          this.status = Status.FOUND;
          this.claim = response.data as Claim;
          this.category = this.claim.Category.ID;
          this.lob = this.claim.LineOfBusiness.Name;
        })
        .catch(err => {
          this.getCSRFToken();
          this.loading = false;
          this.status = Status.IDLE;
          this.claim = null;
          this.category = null;
          this.lob = "";
          this.hasClaimRequest = false;
          this.claimRequest = DEFAULT_CLAIM_REQUEST;
          this.errors = [extractErrorMessage(err)];
          scrollBehavior("#claim-submission-errors");
          console.error(err);
        });
    } else {
      this.errors = ["Valid PIN is required. Minimum length 7"];
      scrollBehavior("#claim-submission-errors");
    }
  }

  submit() {
    this.errors = [];
    if (!this.hasClaimRequest) this.$set(this.claimRequest, "Description", "");
    this.validation();

    if (Object.keys(this.formerrors).length) {
      this.loading = false;
      this.status = Status.FOUND;
      this.errors = flatten(Object.values(this.formerrors));
      scrollBehavior("#claim-submission-errors");
      return;
    }

    if (confirm("Please confirm submission.")) {
      if (this.claim) {
        this.errors = [];
        this.loading = true;
        this.status = Status.SUBMITTING;
        axios({
          url: `/api/v1/claims/${this.claim.ID}/submission`,
          method: "POST",
          data: {
            phone: this.phoneNumber,
            email: this.emailAddress,
            claimRequest: this.hasClaimRequest ? this.claimRequest : false,
            primaryContactInfo:
              this.preferredContactMethod === "emailAddress"
                ? "EMAIL"
                : "PHONE",
            _csrf: this.csrfToken
          }
        })
          .then(() => {
            this.loading = false;
            this.status = Status.SUCCESS;
          })
          .catch(err => {
            this.getCSRFToken();
            this.loading = false;
            this.status = Status.FOUND;
            this.errors = [extractErrorMessage(err)];
            scrollBehavior("#claim-submission-errors");
            console.error(err);
          });
      }
    }
  }

  beforeDestroy() {
    this.reset();
  }
}
